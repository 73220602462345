<template>
  <div>
    <v-card
        class="mb-2"
        tile
        flat
        :class="{'important-news-item-card': newsItem.important, 'unread': !isRead}"
        @click="goToNewsDetailView"
        :ripple="false"
    >
      <div v-if="isNotRead" class="event-type-bar rounded-0 primary"></div>

      <div class="d-flex justify-space-between mb-2 mx-4">
        <div
            v-if="isNotRead"
            class="text-subtitle-2 SystemWhite--text primary pa-3"
            v-text="$t('unread')"
        />
        <v-spacer/>

        <v-btn
            v-if="!isHidden"
            icon
            color="SystemCTA"
            v-on:click.prevent
            @click.stop="initToggleFavourite"
            :disabled="toggleFavouriteRequested"
        >
          <v-icon>{{ isFavourite ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
        </v-btn>
        <v-menu min-width="220" tile offset-y offset-x
                v-if="isMarkAsUnreadMenuItemShown || isHideMenuItemShown || isUnHideMenuItemShown">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary"
                   icon
                   v-bind="attrs"
                   v-on="on"
                   v-on:click.prevent>
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list class="py-0">
            <v-list-item v-if="isMarkAsUnreadMenuItemShown" @click.stop="initMarkAsUnread">
              <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                <v-icon size="25" color="SystemCTA">mdi-close-box-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('actionMarkAsUnread') }}</v-list-item-title>
            </v-list-item>
            <v-divider v-if="!isHidden"/>
            <v-list-item v-if="isHideMenuItemShown" @click.stop="initHideNewsItem">
              <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                <v-icon size="25" color="SystemCTA">mdi-eye-off</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('actionHide') }}</v-list-item-title>
            </v-list-item>
            <v-divider v-if="isHidden"/>
            <v-list-item v-if="isUnHideMenuItemShown" @click.stop="initUnHideNewsItem">
              <v-list-item-icon :class="$vuetify.rtl ? 'ml-3' : 'mr-3'">
                <v-icon size="25" color="SystemCTA">mdi-eye</v-icon>
              </v-list-item-icon>
              <v-list-item-title>{{ $t('unHideNewsItem') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <div class="d-flex justify-space-between mt-1 mb-1 mx-4">
        <div v-if="showPostedBy" class="text-body-2 font-weight-medium Text02--text" v-text="newsItem.poster"/>

        <v-spacer/>

        <div class="text-body-2 Text02--text" v-text="date"/>
      </div>

      <div v-if="newsItem.groups.length" class="text-body-2 Text02--text mb-2 text-truncate px-4">
        {{ newsItem.groups.map(group => group.name).join(" • ") }}
      </div>

      <v-list-item three-line>
        <v-list-item-content class="py-0">

          <v-list-item-title class="text-subtitle-1 Text01--text">
            {{ newsItem.title }}
          </v-list-item-title>

          <v-list-item-subtitle :inner-html.prop="newsItem.content" class="my-2 text-body-1 Text01--text">
            {{ newsItem.content }}
          </v-list-item-subtitle>

          <video-player
              v-if="newsItem.videoUrl"
              :videoThumbnail="newsItem.videoThumbnail"
              :videoUrl="newsItem.videoUrl"
              :videoType="newsItem.videoType"
          />

          <deadline-alert :newsItem="newsItem"/>
        </v-list-item-content>
      </v-list-item>

      <v-card-actions class="d-block px-4">
        <div class="d-flex justify-space-between mb-1">
          <news-chips :news-item="newsItem"/>
          <PinUnpinButton
              v-if="!isHidden"
              :news-item.sync="newsItem"
              @togglePinNewsItem="togglePinNewsItem"
          />
        </div>
      </v-card-actions>
    </v-card>

    <v-progress-linear
        height="6"
        color="GreyFooter"
        value="94"
        class="mb-2 ml-4"
    />

  </div>

</template>

<script>
import VideoPlayer from '@/components/pages/NewsItemPage/components/VideoPlayer';
import NewsChips from "@/components/pages/NewsItemPage/components/NewsChips";
import PinUnpinButton from "@/components/pages/NewsListPage/components/PinUnpinButton";
import DeadlineAlert from "@/components/pages/NewsItemPage/components/DeadlineAlert";
import moment from "moment";
import {mapGetters, mapActions} from "vuex";
import {UserGetters} from "@/store/user";
import {NewsActions, NewsGetters} from "@/store/news";

export default {
  name: "NewsListItem",

  components: {
    VideoPlayer,
    NewsChips,
    PinUnpinButton,
    DeadlineAlert,
  },
  props: {
    newsItem: {
      type: Object,
      required: true
    },
    isHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
    isPinned: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data() {
    return {
      isFavourite: false,
      toggleFavouriteRequested: false,
      isRead: true,
    }
  },
  mounted() {
    this.isFavourite = this.newsItem.favourite;
    this.isRead = this.newsItem.read === '1';
  },
  computed: {
    ...mapGetters({
      members: `user/${UserGetters.Members}`,
      unreadNewsCount: `news/${NewsGetters.UnreadNewsCount}`
    }),
    date() {
      return this.newsItem ? moment.utc(this.newsItem.date).format(this.$t('newsHeaderDateTime')) : null;
    },
    showPostedBy() {
      return (this.newsItem.poster && this.newsItem.poster.length > 0);
    },
    isUnHideMenuItemShown() {
      return this.isHidden;
    },
    isHideMenuItemShown() {
      return !this.isHidden && !this.isPinned;
    },
    isMarkAsUnreadMenuItemShown() {
      return !this.isHidden && this.isRead;
    },
    isNotRead() {
      return this.newsItem.read === '0';
    },
  },
  watch: {
    unreadNewsCount() {
      if (this.unreadNewsCount === 0) {
        this.isRead = true;
      }
    }
  },
  methods: {
    ...mapActions({
      toggleFavourite: `news/${NewsActions.ToggleFavourite}`,
      markAsUnread: `news/${NewsActions.MarkAsUnread}`,
      hideNewsItem: `news/${NewsActions.HideNewsItem}`,
      unHideNewsItem: `news/${NewsActions.UnHideNewsItem}`,
    }),
    async initToggleFavourite() {
      this.toggleFavouriteRequested = true;
      this.toggleFavourite(this.newsItem.id).then(() => {
        this.isFavourite = !this.isFavourite;
        this.toggleFavouriteRequested = false;
        this.$emit('toggleFavourite', {id: this.newsItem.id, isFavourite: this.isFavourite});

        if (this.isFavourite) {
          this.$toasted.info(this.$t('newsItemAddedToFavourites'));
        } else {
          this.$toasted.info(this.$t('newsItemRemovedFromFavourites'));
        }
      }).catch((e) => {
        this.$toasted.error(e.message);
        this.toggleFavouriteRequested = false;
      });
    },
    async initMarkAsUnread() {
      if (!this.isRead) {
        return;
      }
      this.markAsUnread(this.newsItem.id).then(() => {
        this.$emit("update:newsItem", { ...this.newsItem, read: '0'})
        this.$emit("update:isRead", false)
        //this.newsItem.read = '0';
        //this.isRead = false;
      }).catch((e) => {
        this.$toasted.error(e.message);
      });
    },
    async initHideNewsItem() {
      this.hideNewsItem({
        newsId: this.newsItem.id,
        isRead: this.isRead
      }).then(() => {
        this.$toasted.info(this.$t('newsItemHidden'));
        this.$emit('hideNewsItem', this.newsItem.id);
      }).catch((e) => {
        this.$toasted.error(e.message);
      });
    },
    async initUnHideNewsItem() {
      this.unHideNewsItem(this.newsItem.id)
          .then(() => {
            this.$toasted.info(this.$t('newsItemUnhidden'));
            this.$emit('unHideNewsItem', this.newsItem.id)
          }).catch((e) => {
        this.$toasted.error(e.message);
      });
    },
    goToNewsDetailView() {
      if (this.$route.name.includes("pinned") && this.isPinned) {
        this.$router.push({name: 'news.pinned.show', params: {id: this.newsItem.id}});
      } else {
        this.$router.push({name: 'news.show', params: {id: this.newsItem.id}});
      }
    },
    togglePinNewsItem(params) {
      this.$emit('togglePinNewsItem', params);
    }
  }
}
</script>

<style scoped lang="scss">
  .v-card {
    &:focus {
      &:before {
        opacity: 0;
      }
    }
  }

  .event-type-bar {
    width: 5px;
    min-width: 5px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
</style>
