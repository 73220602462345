<template>
  <v-dialog
      v-model="dialog"
      :max-width="600"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      scrollable
      @click:outside="close"
      overlay-color="#061F4B"
      overlay-opacity="0.8"
  >
    <v-card :disabled="submitLoading" :loading="submitLoading" tile>
      <v-toolbar flat color="transparent">
        <span class="text-subtitle-1 Text01--text">{{ titleText }}</span>
        <v-spacer/>
        <v-btn :class="$vuetify.rtl ? 'ml-0' : 'mr-0'" icon color="Text03" @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider/>
      <v-card-title class="subtitle-2 Text01--text">
        {{ $t('absentTxtInst', {member: member.name}) }}
      </v-card-title>
      <v-card-text>
        <v-form @submit.prevent="createUpdateSubmit">
          <v-container class="px-0">
            <v-row>
              <v-col class="pb-0" md="6" cols="12">
                <v-menu
                    tile
                    v-model="startDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('absentTxtStartDate')"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="displayStartDate"
                        @click:clear="startDate = null"
                        clearable
                        :error-messages="startDateErrors"
                        :disabled="isStarted"
                    />
                  </template>
                  <v-date-picker
                      v-model="startDate"
                      :min="currentDate"
                      no-title
                      @input="startDatePicked"
                      :locale="language"
                      @change="setTime"
                  />
                </v-menu>

              </v-col>
              <v-col class="pb-0" md="6" cols="12">
                <v-menu
                    v-if="!entireDay"
                    v-model="startTimePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('absentTxtStartTime')"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :value="displayStartTime"
                        :disabled="isStarted"
                    />
                  </template>
                  <v-time-picker
                      v-model="startTime"
                      required
                      :allowed-minutes="allowedStep"
                      :format="displayFormat"
                      @click:hour="setTime"
                      @click:minute="setTime"
                  >
                    <v-spacer/>
                    <v-btn
                        text
                        color="primary"
                        @click="startTimePicker = false"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="startTimePicker = false"
                    >
                      {{ $t('ok') }}
                    </v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>
              <v-col class="py-0" md="6" cols="12">
                <v-menu
                    v-model="endDatePicker"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('absentTxtEndDate')"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :value="displayEndDate"
                        @click:clear="endDate = null"
                        clearable
                        :error-messages="endDateErrors"
                    />
                  </template>
                  <v-date-picker
                      v-model="endDate"
                      :min="startDate"
                      no-title
                      @input="endDatePicker = false"
                      :locale="language"
                      @change="setTime"
                  />
                </v-menu>
              </v-col>
              <v-col class="py-0" md="6" cols="12">
                <v-menu
                    v-if="!entireDay"
                    v-model="endTimePicker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        :label="$t('absentTxtEndTime')"
                        readonly
                        outlined
                        v-bind="attrs"
                        v-on="on"
                        :value="displayEndTime"
                    />
                  </template>
                  <v-time-picker
                      v-model="endTime"
                      required
                      :allowed-minutes="allowedStep"
                      :format="displayFormat"
                      @click:hour="setTime"
                      @click:minute="setTime"
                  >
                    <v-spacer/>
                    <v-btn
                        text
                        color="primary"
                        @click="endTimePicker = false"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                    <v-btn
                        text
                        color="primary"
                        @click="endTimePicker = false"
                    >
                      {{ $t('ok') }}
                    </v-btn>
                  </v-time-picker>
                </v-menu>
              </v-col>

              <v-col class="py-0" cols="12" md="3">
                <v-checkbox v-model="entireDay" class="mt-0">
                  <template v-slot:label>
                    <span class="text-body-1 Text01--text">{{ $t('absentTxtWholeDay') }}</span>
                  </template>
                </v-checkbox>
              </v-col>
              <v-col class="py-0" cols="12" v-if="showAbsentComment">
                <v-textarea
                    :label="$t('absentTxtComments')"
                    v-model="comment"
                    :error-messages="commentErrors"
                    outlined
                    auto-grow
                    counter
                >
                  <template v-slot:counter="{ props: { value } }">
                    <div class="Text03--text text-caption">
                      {{ value }} / 300
                    </div>
                  </template>
                </v-textarea>
              </v-col>
              <v-col class="py-0" cols="12" v-if="showAbsentReason">
                <v-divider class="pb-4"/>
                <p class="subtitle-2 Text01--text">{{ $t('absentTxtReason') }}</p>
                <p v-if="absentReason === 2">
                  <v-sheet
                      class="body-1 Text01--text pa-4 d-flex flex-column my-4"
                      color="SystemBG"
                  >{{ $t('absentTxtReasonOther') }}
                  </v-sheet>
                </p>
                <v-radio-group
                    v-model="absentReason"
                    mandatory
                >
                  <v-radio key="1" :value="1">
                    <template #label>
                      <span class="v-label Text01--text">{{$t('absentTxtHoliday')}}</span>
                    </template>
                  </v-radio>
                  <v-radio key="2" :value="2">
                    <template #label>
                      <span class="v-label Text01--text">{{$t('absentTxtOther')}}</span>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-col>
              <v-col class="py-0" cols="12" v-if="lunchIsEditable">
                <v-divider class="pb-4"/>
                <p class="subtitle-2 Text01--text">{{ $t('memberLunchDetails') }}</p>
                <template v-if="this.mode === 'add'">
                  <v-sheet
                      class="body-1 Text01--text pa-4 d-flex flex-column my-4"
                      color="SystemBG"
                  >
                    <span v-html="lunchCancellationInfoMessage"/>
                  </v-sheet>
                  <v-checkbox v-model="cancelLunches" class="mt-0">
                    <template v-slot:label>
                      <span class="text-body-1 Text01--text">{{ $t('member_absence_cancel_lunch') }}</span>
                    </template>
                  </v-checkbox>
                </template>
                <template v-else>
                  <v-sheet
                      class="body-1 Text01--text pa-4 d-flex flex-column my-4"
                      color="SystemBG"
                  >
                    {{ $t('member_absence_can_edit_via_lunch') }}
                  </v-sheet>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-divider/>
      <v-card-actions
          class="d-flex flex-column flex-sm-row justify-sm-space-between pa-4 gap-4"
          :class="{ 'pb-8': $vuetify.breakpoint.xsOnly }"
      >
        <v-btn
            v-if="showDeleteButton"
            color="AlertRed"
            outlined
            :min-width="106"
            :block="$vuetify.breakpoint.xsOnly"
            :disabled="submitLoading"
            @click="$emit('deleteAbsence')"
        >
          {{ $t('delete') }}
        </v-btn>

        <div class="d-flex w-100 justify-end">
          <v-btn
              v-if="$vuetify.breakpoint.smAndUp"
              class="mr-4"
              color="primary"
              outlined
              :min-width="106"
              :disabled="submitLoading"
              @click="close"
          >
            {{ $t('cancel') }}
          </v-btn>

          <v-btn
              color="primary"
              depressed
              :min-width="106"
              :block="$vuetify.breakpoint.xsOnly"
              :loading="submitLoading"
              @click="createUpdateSubmit"
          >
            {{ saveButtonText }}
          </v-btn>
        </div>
      </v-card-actions>

      <confirmation-modal
          :max-width="544"
          :loading="submitLoading"
          v-model="editConfirmationModal"
          :title="$t('editAbsentMessage')"
          :label="$t('editAbsentLabel')"
          :apply-button-label="$t('edit')"
          apply-button-color="primary"
          @apply="initSubmit"
      />

    </v-card>

  </v-dialog>
</template>

<script>
import moment from 'moment';
import validationMixin from "@/mixins/validationMixin";
import absenceMixin from "@/mixins/absenceMixin";
import {required, maxLength} from "vuelidate/lib/validators";
import {mapActions, mapGetters} from 'vuex';
import {SickListActions, SickListFormModes} from "@/store/sicklist";
import i18next from "i18next";
import {getBCP47LanguageTag} from "@/helpers/helpers";
import ConfirmationModal from "@/components/ConfirmationModal";
import {MemberGetters} from "@/store/member";
import EventBus from '@/services/EventBus';
import { DatePickerFormat, TimePickerFormat } from "@/constants";
import {UserGetters} from "@/store/user";
import dateUtilityMixin from "@/mixins/dateUtilityMixin";

const defaultStartDate = moment().format(DatePickerFormat);
const defaultEndDate = moment().format(DatePickerFormat);
const defaultStartTime = '07:00'; // TimePickerFormat
const defaultEndTime = '18:00'; // TimePickerFormat
const defaultEndMinutesStep = 30;
const defaultTimeMinutesStep = 5;

export default {
  name: "AbsentEntryDialog",

  components: {
    ConfirmationModal
  },

  props: {
    value: {
      type: Boolean
    },
    sickListEntry: {
      type: Object,
      required: false
    },
    mode: {
      type: String
    },
    config: {
      required: true,
    }
  },

  data() {
    return {
      submitLoading: false,
      startDatePicker: false,
      startDate: defaultStartDate,
      startTime: defaultStartTime,
      endDatePicker: false,
      endDate: defaultEndDate,
      endTime: defaultEndTime,
      entireDay: false,
      comment: null,
      sickListEntryId: null,
      saveButtonText: i18next.t('add'),
      titleText: i18next.t('add_absent'),
      showAbsentComment: false,
      showAbsentReason: false,
      absentReason: null,
      cancelLunches: false,
      currentDate: defaultStartDate,
      currentTime: moment().format(TimePickerFormat),
      startTimePicker: false,
      endTimePicker: false,
      editConfirmationModal: false,
      showDeleteButton: false,
      isStarted: false,
    }
  },

  created() {
    const lang = i18next.language === 'en' ? 'en-au' :  i18next.language;
    moment.locale(lang);
  },

  watch: {
    dialog(value) {
      this.startTime = this.currentTime;
      this.showAbsentComment = this.config.showabsentcomment && !this.config.showabsentreason;
      this.showAbsentReason = !this.config.showabsentcomment && this.config.showabsentreason;

      this.resetForm();
      this.setText();
      this.setTime();

      if (this.mode === SickListFormModes.Edit) {
        this.setValues();
      }

      if (value) {
        this.$toasted.clear();
      }
    }
  },

  methods: {
    ...mapActions({
      saveAbsentEntry: `sicklist/${SickListActions.SaveAbsentEntry}`,
    }),
    close() {
      this.$emit('close');
      this.resetForm();
      this.dialog = !this.dialog;
    },
    setText() {
      if (this.mode === SickListFormModes.Edit) {
        this.titleText = i18next.t('edit_absent');
        this.saveButtonText = i18next.t('save');
      } else {
        this.saveButtonText = i18next.t('add');
        this.titleText = i18next.t('add_absent');
      }
    },
    setTime() {
      if (moment(`${this.startDate} ${this.startTime}`).diff(moment(), "minutes") <= 0) {
        const step = defaultTimeMinutesStep - (moment().minutes() % defaultTimeMinutesStep);
        this.startTime = moment().add(step, 'minutes').format(TimePickerFormat);
      }

      if (moment(this.endDate).diff(this.startDate, 'days') === 0) {
        if (moment(`${this.endDate} ${this.endTime}`).diff(`${this.startDate} ${this.startTime}`, 'minutes') < defaultEndMinutesStep) {
          this.endTime = moment(`${this.startDate} ${this.startTime}`).add(defaultEndMinutesStep, 'minutes').format(TimePickerFormat);
        }
      }
    },
    allowedStep: m => m % defaultTimeMinutesStep === 0,
    setValues() {
      if (this.sickListEntry) {
        let startDate = moment(this.sickListEntry.start).format(DatePickerFormat);
        let startTime = moment(this.sickListEntry.start).format(TimePickerFormat);

        let endDate = moment(this.sickListEntry.end).format(DatePickerFormat);
        let endTime = moment(this.sickListEntry.end).format(TimePickerFormat);

        this.startDate = startDate;
        this.startTime = startTime;
        this.endDate = endDate;
        this.endTime = endTime;
        this.entireDay = this.sickListEntry.whole_day;
        this.comment = this.sickListEntry.description;

        if (this.showAbsentReason) {
          this.comment = null;
          this.absentReason = this.sickListEntry.reason;
        }

        this.sickListEntryId = this.sickListEntry.id;
        this.showDeleteButton = this.isCheckInCheckOutActive() ? this.showDeleteInModal(this.sickListEntry) : false;
        this.isStarted = this.isAbsenceStarted(this.sickListEntry);
      }
    },
    startDatePicked() {
      if (this.endDate && moment(this.startDate).isAfter(this.endDate)) {
        this.endDate = this.startDate;
      }
      this.startDatePicker = false
    },
    resetForm() {
      this.startDate = defaultStartDate;
      this.startTime = defaultStartTime;
      this.endDate = defaultEndDate;
      this.endTime = defaultEndTime;
      this.entireDay = false;
      this.comment = null;
      this.absentReason = 0;
      this.isStarted = false;

      if (this.showAbsentReason) {
        this.absentReason = 1;
      }

      this.$v.$reset();
    },

    async createUpdateSubmit() {
      if (this.mode === SickListFormModes.Edit) {
        this.editConfirmationModal = true;
      } else {
        await this.initSubmit();
      }
    },

    async initSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      let startDate = this.startDate;
      let endDate = this.endDate;

      if (!this.entireDay) {
        startDate += ` ${this.startTime}`;
        endDate += ` ${this.endTime}`;
      }

      this.submitLoading = true;
      this.saveAbsentEntry({
        memberId: this.member.id,
        entireDay: this.entireDay,
        start: startDate,
        end: endDate,
        comment: this.comment,
        reason: this.absentReason,
        cancelLunches: this.cancelLunches,
        mode: this.mode,
        id: this.sickListEntryId,
      }).then(() => {
        this.$emit('saveAbsentEntry');

        if (this.mode === SickListFormModes.Edit) {
          this.$toasted.success(this.$t('absenceChangedMessage'));
        } else {
          this.$toasted.success(this.$t('absenceReceivedMessage'));
        }

        EventBus.$emit('isAbsenceListChanged');
      }).catch((e) => {
        this.$emit('saveAbsentEntryError', e);
        this.$toasted.error(e.message);
      }).finally(() => {
        this.submitLoading = false;
        this.close();
      });
    },
  },

  computed: {
    ...mapGetters({
      member: `member/${MemberGetters.Member}`,
      accountConfiguration: `user/${UserGetters.AccountConfiguration}`,
    }),

    commentValidationRules() {
      if (this.showAbsentComment) {
        return {required, maxLengthValue: maxLength(300)};
      }

      return {};
    },
    dialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    },
    commentErrors() {
      return this.getErrorMessagesForFieldWithRules('comment', this.commentValidationRules);
    },
    startDateErrors() {
      return this.getErrorMessagesForFieldWithRules('startDate', {
        required
      });
    },
    endDateErrors() {
      return this.getErrorMessagesForFieldWithRules('endDate', {
        required
      });
    },
    displayStartDate() {
      return this.startDate ? moment(this.startDate).format('L') : '';
    },
    displayEndDate() {
      return this.endDate ? moment(this.endDate).format('L') : '';
    },
    displayStartTime() {
      return this.startDate ? moment(`${this.startDate} ${this.startTime}`).format('LT') : null;
    },
    displayEndTime() {
      return this.endDate ? moment(`${this.endDate} ${this.endTime}`).format('LT') : null;
    },
    displayFormat() {
      return i18next.language === 'en' ? 'ampm' : '24hr';
    },
    language() {
      return getBCP47LanguageTag(i18next.language);
    },
    isItPossibleToCancelLunchForWholePeriod() {
      if (this.isToday(this.startDate)) {
        return !this.hasTimePassed(this.lunchRequiredUntil)
      }
      return true;
    },
    isItPossibleToCancelLunchPartially() {
      const momentEnd = moment(this.endDate, 'YYYY-MM-DD', true)
      return this.lunchCanBeCancelledFrom.isSame(momentEnd, 'day') || this.lunchCanBeCancelledFrom.isBefore(momentEnd);
    },

    prettyMinLunchCancellationDate() {
      return this.lunchCanBeCancelledFrom.format(this.$t('normalDateFormat'));
    },
    lunchIsEditable() {
      return this.accountConfiguration.lunchIsEditableByAppUsers;
    },
    lunchRequiredUntil() {
      return this.accountConfiguration.lunchRequiredUntil;
    },
    lunchCanBeCancelledFrom() {
      return this.getNextWorkingDay(moment());
    },
    lunchCancellationInfoMessage() {
      if (this.isItPossibleToCancelLunchForWholePeriod) {
        return this.$t('member_absence_can_be_cancelled');
      }
      if (this.isItPossibleToCancelLunchPartially) {
        return this.$t('member_absence_can_be_cancelled_onwards_only', {'date': this.prettyMinLunchCancellationDate});
      }
      return this.$t('member_absence_cannot_be_cancelled')
    }
  },

  mixins: [validationMixin, absenceMixin, dateUtilityMixin],

  validations() {
    return {
      comment: this.commentValidationRules,
      startDate: {required},
      endDate: {required},
    }
  },
}
</script>

<style scoped/>