<template>
  <div>
    <v-dialog
        v-if="isImage"
        v-model="dialog"
        scrollable
        fullscreen
    >
      <v-card color="rgba(6, 31, 75, 0.8)" tile elevation="0" @click="close" :ripple="false">
        <v-app-bar height="66" flat color="transparent" class="px-md-5">
          <v-btn color="SystemWhite" icon @click.stop.prevent="close">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>

          <v-spacer/>

          <v-toolbar-title class="text-subtitle-1 SystemWhite--text">
            {{ title }}
          </v-toolbar-title>

          <v-spacer/>

          <v-btn color="SystemWhite" icon @click.stop.prevent="download()">
            <v-icon>mdi-download</v-icon>
          </v-btn>

          <v-btn color="SystemWhite" icon @click.stop.prevent="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-app-bar>

        <v-card-text
            v-if="isImage"
            ref="container"
            style="max-width: 800px;"
            class="align-self-center pa-0 mb-md-15 mt-md-5"
            @click.stop.prevent
        >
          <v-img :src="attachmentSrc"/>
        </v-card-text>

        <SkeletonLoader v-if="overlay"/>
      </v-card>
    </v-dialog>
    <pdf-viewer-dialog v-else-if="isPdf" :title="title" :pdfData="attachment" @close="close"/>
  </div>
</template>

<script>
import path from "path";
import mime from "mime-types";
import { downloadBlob } from "@/helpers/helpers";
import PdfViewerDialog from "@/components/PdfViewerDialog";

const IMAGES = [ "bmp", "png", "jpg", "jpeg", "gif" ];

export default {
  name: "AttachmentPreviewModal",

  components: {PdfViewerDialog},

  props: {
    value: {
      type: Boolean
    },
    title: {
      type: String,
      required: false,
      default: null
    },
    attachment: {
      type: [ File, ArrayBuffer ],
      default: null
    }
  },

  data() {
    return {
      numPages: 0,
      attachmentSrc: null,
      arrayBuffer: null,
      overlay: true
    };
  },

  computed: {
    dialog: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit("input", newValue);
      }
    },

    extension() {
      return path.extname(this.title).substring(1).toLowerCase();
    },

    type() {
      return mime.lookup(this.extension);
    },

    isPdf() {
      return this.extension === "pdf";
    },

    isImage() {
      return IMAGES.includes(this.extension);
    },

    isArrayBuffer() {
      return this.attachment instanceof ArrayBuffer;
    }
  },

  watch: {
    "dialog": {
      immediate: true,
      async handler(dialog) {
        if (dialog) {
          await this.convertToArrayBuffer();
          await this.parseAttachment();
        }
      }
    },
  },

  methods: {
    close() {
      this.dialog = false;
      this.numPages = 0;
      this.attachmentSrc = null;
      this.arrayBuffer = null;

      this.$emit("close");
    },

    async convertToArrayBuffer() {
      if (this.isArrayBuffer) {
        this.arrayBuffer = this.attachment;
      } else {
        this.arrayBuffer = await this.attachment.arrayBuffer();
      }
    },

    async parseAttachment() {
      if (this.isImage) {
        this.attachmentSrc = "data:" + this.type + ";base64," + this.arrayBufferToBase64(this.arrayBuffer);

        this.stopOverlay();
      }
    },

    download() {
      const data = new Blob([ this.arrayBuffer ], { type: this.type });

      downloadBlob(data, this.type, this.title)
    },

    arrayBufferToBase64(buffer) {
      let binary = "";
      let bytes = new Uint8Array(buffer);
      let len = bytes.byteLength;

      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },

    stopOverlay() {
      this.overlay = false;
    }
  }
}
</script>

<style scoped/>
