import axios from "@/plugins/axiosInstance";

const prefix = '/api/app-user';

export const AdditionalContactsService = {
    async index(memberId, page) {
        return axios({requiresAuth: true}).get(`${prefix}/children/${memberId}/additional-contacts`, {params: {perPage: 5, page}});
    },

    async show(id) {
        return axios({requiresAuth: true}).get(`${prefix}/additional-contacts/${id}`);
    },

    async destroy(id) {
        return axios({requiresAuth: true}).delete(`${prefix}/additional-contacts/${id}`);
    },

    async create(formData) {
        return axios({requiresAuth: true}).post(`${prefix}/additional-contacts`, formData);
    },

    async update(id, formData) {
        return axios({requiresAuth: true}).post(`${prefix}/additional-contacts/${id}`, formData);
    },

    async fetchAvatar(id) {
        return axios({requiresAuth: true}).get(`${prefix}/additional-contacts/${id}/avatar`, { responseType: "blob" });
    },

    async fetchImage(id) {
        return axios({requiresAuth: true}).get(`${prefix}/additional-contacts/${id}/image`, { responseType: "blob" });
    },
}

export const PickupLunchDetailsService = {
    async index(memberId, startDate, endDate) {
        const params = {
            start_date: startDate,
            end_date: endDate
        };
        return axios({requiresAuth: true}).get(`${prefix}/children/${memberId}/pickup-lunch-details`,  { params });
    },
    async show(memberId, date) {
        return axios({requiresAuth: true}).get(`${prefix}/children/${memberId}/pickup-lunch-details/${date}`);
    },
    async update(memberId, formData) {
        return axios({requiresAuth: true}).post(`${prefix}/children/${memberId}/pickup-lunch-details`, formData);
    },
}

export const NewsService = {
    async attachment(newsId, attachmentId) {
        return axios({requiresAuth: true}).get(`${prefix}/news/${newsId}/attachments/${attachmentId}`, {
            responseType: "arraybuffer"
        });
    }
}