<template>
  <div>
    <v-row v-if="memberDetail.absences.length" class="mb-5">
      <v-col cols="12">
        <div
            v-for="(absence, index) in memberDetail.absences"
            :key="index"
        >
          <section class="my-6">
            <header class="text-subtitle-1 Text01--text">{{ $t('memberAbsent') }}</header>
          </section>
          <InfoItem
              icon="mdi-account-off"
              :is-text-bold="true"
              :textContent="getAbsentTextForCard(absence)"
          />

          <template v-if="absence.description">
            <v-divider class="my-3"/>
            <InfoItem icon="mdi-message-reply-text" :textContent="absence.description"/>
          </template>
        </div>

      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <section class="my-6">
          <header class="text-subtitle-1 Text01--text">{{ $t('memberLunchDetails') }}</header>
        </section>

        <template v-if="isPast(memberDetail.date)">
          <v-sheet
              class="body-1 Text01--text pa-4 d-flex flex-column my-4"
              color="SystemBG"
          >{{ $t('cant_edit_lunch_disclaimer') }}
          </v-sheet>
        </template>
        <template v-else-if="!lunchIsEditable">
          <InfoMessage
              class="my-4"
              :title="$t('members_lunch_cannot_be_changed_info_message')"
              :description="$t('members_lunch_cannot_be_changed_description')"
          />
        </template>
        <template v-else>
          <InfoMessage
              class="my-4"
              :title="$t('member_lunch_cancel_only_disclaimer')"
              :description="$t('member_lunch_cancel_only_disclaimer_description')"
          />
        </template>

        <InfoItem
            icon="$fast_food_fill"
            :textContent="formatLunchRequired(memberDetail.lunch_required)"
            :is-text-bold="true"
        >
          <SquareText
              :shouldDisplay="memberDetail.lunch_required_old !== null"
              :text="formatLunchRequired(memberDetail.lunch_required_old)"
          />
        </InfoItem>

        <template v-if="memberDetail.lunch_required_notes">
          <v-divider class="my-3"/>
          <InfoItem icon="mdi-message-reply-text" :textContent="memberDetail.lunch_required_notes"/>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import pickupLunchMixin from "@/mixins/pickupLunchMixin";
import dateUtilityMixin from "@/mixins/dateUtilityMixin";
import InfoItem from "@/components/pages/MemberPage/components/PickupTimesLunch/InfoItem";
import SquareText from "@/components/pages/MemberPage/components/PickupTimesLunch/SquareText";
import InfoMessage from "@/components/pages/MemberPage/components/PickupTimesLunch/InfoMessage.vue";

export default {
  name: "ViewDetails",

  components: {InfoMessage, SquareText, InfoItem},

  mixins: [pickupLunchMixin, dateUtilityMixin],

  props: {
    memberDetail: {
      type: Object,
      required: true
    },
  },
}
</script>

