<template>
  <div style="display: none"></div>
</template>

<script>
// import pdf from 'vue-pdf'
import {downloadBlob} from "@/helpers/helpers";

export default {
  name: "PdfViewerDialog",

  components: {
    // pdf
  },

  props: {
    value: {
      type: Boolean,
    },
    title: {
      type: String
    },
    pdfData: {
      type: ArrayBuffer
    }
  },

  data() {
    return {
      numPages: 0,
      pdfSrc: null,
      overlay: true,
    }
  },

  computed: {
    dialog: {
      get: function () {
        return this.value;
      },
      set: function (newValue) {
        if (!newValue) {
          this.overlay = true;
        }

        this.$emit('input', newValue);
      }
    },
  },

  watch: {
    pdfData: {
      immediate: true,
      async handler() {
        if (!this.pdfData) {
          return;
        }
        // this.pdfSrc = await pdf.createLoadingTask({
        //   data: this.pdfData
        // });
        // await this.pdfSrc.promise.then(pdf => {
        //   this.numPages = pdf.numPages;
        //   this.$forceUpdate();
        // });

        const blob = new Blob([this.pdfData], { type: "application/pdf" });
        const blobUrl = URL.createObjectURL(blob);

        // Open the Blob URL in a new tab
        window.open(blobUrl, '_blank',);
        this.close();
      }
    }
  },

  methods: {
    download() {
      downloadBlob(this.pdfData, 'application/pdf', this.title)
    },

    close() {
      this.$emit('close');
      this.dialog = false;
    },
  }
}
</script>
