import {UserGetters} from "@/store/user";
import {NewsService} from '@/services/ApiServices';
import {NewsService as NewsAdminService} from '@/services/AdminApiService';
import {defaultApiPromiseHandler} from "@/helpers/helpers";

export const NewsFilterType = {
    FilterUnread: 'filter_unread',
    FilterFavourites: 'favouritefilter'
};

const initialState = () => ({
    newsItems: [],
    lastPinnedNewsItems: [],
    pinnedNewsCount: null,
    unreadNewsCount: null,
    newsCount: null,
    hasDirectNews: false,
    filters: {
        filter_by_type: "all",
        filter_by_statuses: [],
        filter_by_groups_ids: [],
        filter_by_receiver_type: "all",
    },
});

export const NewsGetters = {
    NewsItems: 'newsItems',
    UnreadNewsCount: 'unreadNewsCount',
    NewsCount: 'newsCount',
    Filters: 'filters',
    HasDirectNews: 'hasDirectNews',
    LastPinnedNewsItems: 'lastPinnedNewsItems',
    PinnedNewsCount: 'pinnedNewsCount',
};

export const NewsActions = {
    FetchNewsItems: 'fetchNewsItems',
    FetchNewsGroups: 'fetchNewsGroups',
    FetchPinnedNews: 'fetchPinnedNews',
    FetchLastPinnedNews: 'fetchLastPinnedNews',
    FetchHiddenNewsItems: 'fetchHiddenNewsItems',
    FetchNewsItem: 'fetchNewsItem',
    FetchAttachment: 'fetchAttachment',
    SendResponse: 'sendResponse',
    ToggleFavourite: 'toggleFavourite',
    MarkAllAsRead: 'markAllAsRead',
    UnpinAllNewsItems: 'unpinAllNewsItems',
    MarkAsUnread: 'markAsUnread',
    HideNewsItem: 'hideNewsItem',
    UnHideNewsItem: 'unHideNewsItem',
    UpdateFilters: 'updateFilters',
    PinUnpinNewsItem: 'pinUnpinNewsItem',
};

export const NewsMutations = {
    SetNewsItems: 'setNewsItems',
    SetUnreadNewsCount: 'setUnreadNewsCount',
    SetNewsCount: 'setNewsCount',
    SetFilters: 'setFilters',
    SetHasDirectNews: 'setHasDirectNews',
    SetLastPinnedNewsItems: 'setLastPinnedNewsItems',
    SetPinnedNewsCount: 'setPinnedNewsCount',
    TruncateFilter: 'truncateFilter',
};

export const ResponseTypes = {
    Text: 'textfb',
    Multi: 'multi',
    Single: 'single'
};

export const NEWS_PAGE_SIZE = 20;

const state = initialState()

const getters = {
    [NewsGetters.NewsItems](state) {
        return state.newsItems;
    },
    [NewsGetters.UnreadNewsCount](state) {
        return state.unreadNewsCount;
    },
    [NewsGetters.NewsCount](state) {
        return state.newsCount;
    },
    [NewsGetters.HasDirectNews](state) {
        return state.hasDirectNews;
    },
    [NewsGetters.Filters](state) {
        return state.filters;
    },
    [NewsGetters.LastPinnedNewsItems](state) {
        return state.lastPinnedNewsItems;
    },
    [NewsGetters.PinnedNewsCount](state) {
        return state.pinnedNewsCount;
    },
};

const actions = {
    async [NewsActions.FetchNewsItems]({rootGetters, commit, getters}, params = {}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return NewsService.getListItems(associationId, authenticatedUser.user_id, params)
            .then(({data}) => {
                if (Object.prototype.hasOwnProperty.call(params, 'offset') && params.offset > 0) {
                    commit(NewsMutations.SetNewsItems, [...getters[NewsGetters.NewsItems], ...data.items]);
                } else {
                    commit(NewsMutations.SetNewsItems, data.items);
                }
                commit(NewsMutations.SetUnreadNewsCount, data.unread);
                commit(NewsMutations.SetNewsCount, data.count);
                commit(NewsMutations.SetHasDirectNews, data.has_direct_news);

                return data.items
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.FetchHiddenNewsItems]({rootGetters}, params) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        params.hiddenOnly = true;

        return NewsService.getListItems(associationId, authenticatedUser.user_id, params)
            .then(({data}) => {
                return data.items
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.FetchPinnedNews]({rootGetters}, params) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return NewsService.getPinnedNews(associationId, authenticatedUser.user_id, params)
            .then(({data}) => {
                return data.items
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.FetchLastPinnedNews]({rootGetters, commit}, params) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return NewsService.getLastPinnedNews(associationId, authenticatedUser.user_id, params)
            .then(({data}) => {
                const {items, count} = data;
                commit(NewsMutations.SetPinnedNewsCount, count);
                commit(NewsMutations.SetLastPinnedNewsItems, items);
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.FetchNewsItem]({rootGetters}, {id, translationLang}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return NewsService.getListItem(associationId, authenticatedUser.user_id, id, translationLang).then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [NewsActions.FetchAttachment](_, {newsId, attachmentId}) {
        return NewsAdminService.attachment(newsId, attachmentId).then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [NewsActions.SendResponse]({rootGetters}, {newsItem, formData}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return NewsService.sendResponse(associationId, authenticatedUser.user_id, newsItem, formData)
            .then(({data}) => {
                if (data.success && data.success === '1') {
                    return data;
                } else {
                    throw new Error(data.message);
                }
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.ToggleFavourite]({rootGetters}, newsId) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];

        return NewsService.toggleFavourite(associationId, newsId)
            .then(({data}) => {
                return defaultApiPromiseHandler(data);
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.MarkAllAsRead]({rootGetters, commit}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];

        return NewsService.markAllAsRead(associationId)
            .then(({data}) => {
                if (data.success && data.success === '1') {

                    commit(NewsMutations.SetUnreadNewsCount, 0);

                    return data;
                } else {
                    throw new Error(data.message);
                }
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.UnpinAllNewsItems]({rootGetters, commit}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];

        return NewsService.unpinAllNewsItems(associationId)
            .then(({data}) => {
                if (data.success && data.success === '1') {

                    commit(NewsMutations.SetUnreadNewsCount, 0);

                    return data;
                } else {
                    throw new Error(data.message);
                }
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.MarkAsUnread]({rootGetters, commit, getters}, newsId) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];

        return NewsService.markAsUnread(associationId, newsId)
            .then(({data}) => {
                if (data.success && data.success === '1') {

                    const unreadNewsCount = getters[NewsGetters.UnreadNewsCount] + 1;
                    commit(NewsMutations.SetUnreadNewsCount, unreadNewsCount);

                    return data;
                } else {
                    throw new Error(data.message);
                }
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.PinUnpinNewsItem]({rootGetters}, {newsId, isPinned}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];

        return NewsService.markNewsItemAsPinnedUnpinned(associationId, newsId, isPinned)
            .then(({data}) => {
                if (data.success && data.success === '1') {
                    return data;
                } else {
                    throw new Error(data.message);
                }
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.HideNewsItem]({rootGetters, commit, getters}, {newsId, isRead}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return NewsService.hideNewsItem(associationId, authenticatedUser.user_id, newsId)
            .then(({data}) => {
                if (data.success && data.success === '1') {

                    if (!isRead) {
                        commit(NewsMutations.SetUnreadNewsCount, getters[NewsGetters.UnreadNewsCount] - 1);
                    }

                    return data;
                } else {
                    throw new Error(data.message);
                }
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.UnHideNewsItem]({rootGetters}, newsId) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return NewsService.unHideNewsItem(associationId, authenticatedUser.user_id, newsId)
            .then(({data}) => {
                if (data.success && data.success === '1') {
                    return data;
                } else {
                    throw new Error(data.message);
                }
            }).catch((e) => {
                throw new Error(e.response.data.message);
            });
    },
    async [NewsActions.FetchNewsGroups]({rootGetters}) {
        const associationId = rootGetters[`user/${UserGetters.AssociationId}`];
        const authenticatedUser = rootGetters[`user/${UserGetters.User}`];

        return NewsService.newsGroups(associationId, authenticatedUser.user_id)
            .then(({data}) => {
                return data;
            });
    },
    [NewsActions.UpdateFilters]({commit}, filters) {
        commit(NewsMutations.SetFilters, filters);
    },
};

const mutations = {
    [NewsMutations.SetNewsItems](state, newsItems) {
        state.newsItems = newsItems;
    },
    [NewsMutations.SetUnreadNewsCount](state, unreadNewsCount) {
        state.unreadNewsCount = unreadNewsCount;
    },
    [NewsMutations.SetNewsCount](state, newsCount) {
        state.newsCount = newsCount;
    },
    [NewsMutations.SetHasDirectNews](state, payload) {
        state.hasDirectNews = payload;
    },
    [NewsMutations.SetFilters](state, filters) {
        state.filters = filters;
    },
    [NewsMutations.SetLastPinnedNewsItems](state, payload) {
        state.lastPinnedNewsItems = payload;
    },
    [NewsMutations.SetPinnedNewsCount](state, payload) {
        state.pinnedNewsCount = payload;
    },
    [NewsMutations.TruncateFilter](state) {
        state.filters = initialState().filters;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
