import { render, staticRenderFns } from "./TranslationLanguagePage.vue?vue&type=template&id=3fb71be2&scoped=true"
import script from "./TranslationLanguagePage.vue?vue&type=script&lang=js"
export * from "./TranslationLanguagePage.vue?vue&type=script&lang=js"
import style0 from "./TranslationLanguagePage.vue?vue&type=style&index=0&id=3fb71be2&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fb71be2",
  null
  
)

export default component.exports