import {PickupLunchDetailsService} from "@/services/AdminApiService";
import {serialize} from "object-to-formdata";
import _ from "lodash";

const initialState = () => ({
    list: [],
    model: {},
    changed: {
        date: null,
        pickup_active: null,
        pickup_time: null,
        pickup_time_old: null,
        pickup_time_notes: null,
        lunch_required: null,
        lunch_required_old: null,
        lunch_required_notes: null,
        absences: [],
    },
    loading: false,
});
export const PickupLunchDetailsGetters = {
    List: 'list',
    Model: 'model',
    Changed: 'changed',
    Loading: 'loading',
};
export const PickupLunchDetailsMutations = {
    SetList: 'setList',
    SetModel: 'setModel',
    SetChanged: 'setChanged',
    SetChangedAbsences: 'setChangedAbsences',
    TruncateChanged: 'truncateChanged',
    SetLoading: 'setLoading',
};
export const PickupLunchDetailsActions = {
    FetchPickupLunchDetails: 'fetchPickupLunchDetails',
    FetchPickupLunchDetail: 'fetchPickupLunchDetail',
    UpdatePickupLunchDetail: 'updatePickupLunchDetail',
};

const state = initialState();

const getters = {
    [PickupLunchDetailsGetters.List](state) {
        return state.list
    },
    [PickupLunchDetailsGetters.Model](state) {
        return state.model
    },
    [PickupLunchDetailsGetters.Changed](state) {
        return state.changed
    },
    [PickupLunchDetailsGetters.Loading](state) {
        return state.loading;
    },
};

const mutations = {
    [PickupLunchDetailsMutations.SetList](state, payload) {
        state.list = payload;
    },
    [PickupLunchDetailsMutations.SetModel](state, payload) {
        state.model = formatPayload(payload);
    },
    [PickupLunchDetailsMutations.SetChanged](state, payload) {
        state.changed = {
            ...formatPayload(payload),
            pickup_time_old: payload.changed_pickup ? payload.standard_pickup_time : null,
            lunch_required_old: payload.changed_lunch_required !== null ? payload.standard_lunch_required : null,
        };
    },
    [PickupLunchDetailsMutations.SetChangedAbsences](state, payload) {
        state.changed.absences = payload;
    },
    [PickupLunchDetailsMutations.TruncateChanged](state) {
        state.changed = initialState().changed;
    },
    [PickupLunchDetailsMutations.SetLoading](state, payload) {
        state.loading = payload;
    },
};

const actions = {
    async [PickupLunchDetailsActions.FetchPickupLunchDetails]({commit}, {memberId, startDate, endDate}) {
        commit(PickupLunchDetailsMutations.SetLoading, true);

        return PickupLunchDetailsService.index(memberId, startDate, endDate).then(async ({data: {data}}) => {
            commit(PickupLunchDetailsMutations.SetList, data);
            commit(PickupLunchDetailsMutations.SetLoading, false);

            return data;
        }).catch((e) => {
            commit(PickupLunchDetailsMutations.SetLoading, false);
            throw new Error(e?.response?.data?.message || 'Failed to fetch pickup lunch details');
        });
    },

    async [PickupLunchDetailsActions.FetchPickupLunchDetail]({commit}, {memberId, date, setChanged = false}) {
        commit(PickupLunchDetailsMutations.SetLoading, true);

        return PickupLunchDetailsService.show(memberId, date).then(async ({data: {data}}) => {
            if (!_.isEqual(_.keys(data), ['date'])) {
                commit(PickupLunchDetailsMutations.SetModel, data);

                if (setChanged) {
                    commit(PickupLunchDetailsMutations.SetChanged, data);
                }
            }

            commit(PickupLunchDetailsMutations.SetLoading, false);

            return data;
        }).catch((e) => {
            commit(PickupLunchDetailsMutations.SetLoading, false);
            throw new Error(e.response.data.message);
        });
    },

    async [PickupLunchDetailsActions.UpdatePickupLunchDetail]({commit}, {memberId, payload}) {
        commit(PickupLunchDetailsMutations.SetLoading, true);

        const copy = Object.assign({}, payload);

        const formData = serialize(copy, {
            booleansAsIntegers: true,
        });

        return PickupLunchDetailsService.update(memberId, formData).then(async ({data}) => {
            commit(PickupLunchDetailsMutations.SetLoading, false);
            return data;
        })
    },
};

function formatPayload(payload) {
    return {
        date: payload.date,
        pickup_active: payload.changed_pickup ? payload.changed_pickup_time !== null : payload.standard_pickup_time !== null,
        pickup_time: payload.changed_pickup ? payload.changed_pickup_time : payload.standard_pickup_time,
        pickup_time_notes: payload.changed_pickup ? payload.changed_pickup_time_notes : payload.standard_pickup_time_notes,
        standard_lunch_required: payload.standard_lunch_required,
        lunch_required: payload.changed_lunch_required !== null ? payload.changed_lunch_required : payload.standard_lunch_required,
        lunch_required_notes: payload.changed_lunch_required !== null ? payload.changed_lunch_required_notes : payload.standard_lunch_required_notes,
        absences: payload.absences,
    };
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};