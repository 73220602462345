import { render, staticRenderFns } from "./InfoMessage.vue?vue&type=template&id=6b4f8ae0&scoped=true"
import script from "./InfoMessage.vue?vue&type=script&lang=js"
export * from "./InfoMessage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b4f8ae0",
  null
  
)

export default component.exports