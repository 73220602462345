import axios from "axios";
import AuthPayload from "@/models/AuthPayload";
import i18next from 'i18next';
import Vue from "vue";

function isRefreshTokenError(error) {
    return Object.prototype.hasOwnProperty.call(error, 'isRefreshTokenError')
        && error.isRefreshTokenError;
}

function isExpiredError(error) {
    return Object.prototype.hasOwnProperty.call(error, 'response')
        && Object.prototype.hasOwnProperty.call(error.response, 'status')
        && error.response.status === 500
        && Object.prototype.hasOwnProperty.call(error.response.data, 'message')
        && error.response.data.message === 'EXPIRED';
}

export default ({requiresAuth = false} = {}) => {
    const options = {
        baseURL: process.env.VUE_APP_INFO_API_HOST,
        headers: {
            'Is-Web': true,
            'Language': i18next.language,
            'Accept-Language': i18next.language,
            'Accept': 'application/json',
        }
    };

    if (requiresAuth) {
        const authTokens = AuthPayload.generateRequestAuthTokens();

        options.headers['Auth-Access'] = authTokens.access;
        options.headers['Auth-Public'] = authTokens.public;
        options.headers['Auth-Timestamp'] = authTokens.timestamp;
        options.headers['Auth-Association'] = Vue.cookie.get('association_id');
        options.headers['Auth-User'] = Vue.cookie.get('user_id');
    }

    const instance = axios.create(options);

    instance.interceptors.response.use(response => response, function (error) {
        if (!isExpiredError(error)) {
            return Promise.reject(error);
        }

        if (isRefreshTokenError(error)) {
            return Promise.reject(error);
        }

        return AuthPayload.refreshTokens().then(() => {
            const newTokens = AuthPayload.generateRequestAuthTokens();

            error.response.config.headers['Auth-Access'] = newTokens.access;
            error.response.config.headers['Auth-Public'] = newTokens.public;
            error.response.config.headers['Auth-Timestamp'] = newTokens.timestamp;

            return instance(error.response.config);

        }).catch((error) => {
            error.isRefreshTokenError = true;
            return Promise.reject(error);
        });
    })

    return instance;
};