import {SickListService} from "@/services/ApiServices";
import {defaultApiPromiseHandler} from "@/helpers/helpers";

const initialState = {};

// @todo: move to global ?
export const SickListFormModes = {
    Add: 'add',
    Edit: 'edit',
};

export const SickListActions = {
    FetchSickListEntries: 'fetchSickListEntries',
    SaveAbsentEntry: 'SaveAbsentEntry',
    DeleteAbsentEntry: 'DeleteAbsentEntry',
};

export const ABSENT_ENTRIES_PAGE_SIZE = 20;

const state = { ...initialState };

const actions = {
    async [SickListActions.FetchSickListEntries](context, {memberId, offset = 0, year = null, month = null}) {
        return SickListService.getListItems(memberId, offset, year, month).then(({data}) => {
            return data;
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [SickListActions.SaveAbsentEntry](context, {memberId, entireDay, start, end, comment, reason, cancelLunches, mode, id}) {
        return SickListService.saveAbsentEntry(memberId, {
            entireDay: entireDay,
            start: start,
            end: end,
            comment: comment,
            reason: reason,
            cancelLunches: cancelLunches,
            mode: mode,
            id: id,
        }).then(({data}) => {
            return defaultApiPromiseHandler(data);
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
    async [SickListActions.DeleteAbsentEntry](context, {memberId, absentEntryId}) {
        return SickListService.deleteSickListEntry(memberId, absentEntryId).then(({data}) => {
            return defaultApiPromiseHandler(data);
        }).catch((e) => {
            throw new Error(e.response.data.message);
        });
    },
};


export default {
    namespaced: true,
    state,
    actions,
};
